.registration {
  counter-reset: section;

  &__list {
    margin-top: 1.5rem;
    margin-bottom: 4rem;

    @media (max-width: em(767)) {
      margin-bottom: 2rem;
    }
  }

  &__item {
    position: relative;
    padding-left: 1.5rem;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0.9rem;
      border-radius: 50%;
      width: 0.3rem;
      height: 0.3rem;
      background-color: var(--clr-default-200);
    }

    & + & {
      margin-top: 1rem;
    }
  }

  &__steps {
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: em(1200)) {
      grid-gap: 1.5rem;
    }

    @media (max-width: em(767)) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: em(576)) {
      display: block;
    }
  }

  &__step {
    position: relative;
    border-radius: 0.7rem;
    padding: 4rem;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 130%;
    background-color: var(--clr-primary);

    p {
      position: inherit;
      z-index: 1;
    }

    &::before {
      counter-increment: section;
      content: counter(section);
      position: absolute;
      left: 2rem;
      top: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 5rem;
      font-weight: 600;
      font-size: fluid-font(4.8rem, 3.4rem);
      -webkit-text-stroke: 0.2rem transparent;
      color: var(--clr-primary);
      background-image: linear-gradient(180deg, #f4c062 0%, rgb(244 192 98 / 30%) 100%);
      z-index: 0;
      -webkit-background-clip: text;
      transform: translateY(-50%);

      @media (max-width: em(1200)) {
        left: 1.5rem;
        top: 1rem;
        transform: initial;
      }
    }

    @media (max-width: em(1200)) {
      padding: 2rem 3rem;
    }

    & + & {
      @media (max-width: em(576)) {
        margin-top: 1.5rem;
      }
    }
  }

  .section__text,
  &__item {
    line-height: 155%;
    color: rgb(255 255 255 / 80%);
  }

  &__item + &__item {

  }
}
