.support {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: em(1100)) {
      flex-direction: column;
    }
  }

  &__info {
    margin-right: 1.5rem;
    width: 100%;
    max-width: 56.5rem;

    @media (max-width: em(1100)) {
      margin-right: initial;
      margin-bottom: 2rem;
      max-width: initial;
    }
  }

  &__list {
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    @media (max-width: em(576)) {
      flex-direction: column;
    }
  }

  &__item {
    border-radius: 0.5rem;
    width: 100%;
    max-width: 27rem;
    box-shadow: -15px 30px 40px rgb(14 8 17 / 60%);
    background-color: var(--clr-primary);
    transition: transform var(--transition);

    a {
      display: block;
      padding: 4rem 3rem;

      @media (max-width: em(1200)) {
        padding: 2rem 1.5rem;
      }
    }

    &-img {
      max-width: 5rem;
    }

    &-text {
      font-size: 1.4rem;
      line-height: 135%;
      color: rgb(255 255 255 / 90%);
    }

    &-title {
      margin-bottom: 1.5rem;
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 130%;
      color: var(--clr-default-200);
    }

    @media (max-width: em(576)) {
      max-width: initial;
    }

    @media (hover) {
      &:hover {
        transform: translateY(-3rem);
        transition: transform var(--transition);
      }
    }
  }

  &__item + &__item {
    margin-left: 3rem;

    @media (max-width: em(1200)) {
      margin-left: 1.5rem;
    }

    @media (max-width: em(576)) {
      margin-top: 1.5rem;
      margin-left: initial;
    }
  }
}
