.pros {
  &__wrapper {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      right: 10rem;
      bottom: -5rem;
      z-index: -1;
      width: 70.4rem;
      height: 47.3rem;
      background-image: url("../img/pros/bg@2x.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      @media (max-width: em(992)) {
        display: none;
      }
    }

    &::after {
      content: "";
      position: absolute;
      right: 30rem;
      bottom: 0;
      z-index: -2;
      width: 54.6rem;
      height: 30.1rem;
      background-color: rgb(114 39 98 / 30%);
      filter: blur(160px);
      transform: rotate(-25.82deg);

      @media (max-width: em(992)) {
        display: none;
      }
    }
  }

  &__img {
    margin-right: 1.5rem;
    max-width: 4rem;
  }

  &__inner {
    display: flex;
    align-items: center;
  }
}
