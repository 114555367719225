.reviews {
  &__list {
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 5rem;

    @media (max-width: em(1200)) {
      grid-gap: 1.5rem;
    }

    @media (max-width: em(992)) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: em(767)) {
      grid-template-columns: repeat(2, 1fr);
      margin-top: 2.5rem;
    }

    @media (max-width: em(460)) {
      display: block;
    }
  }

  &__item {
    position: relative;
    overflow: hidden;
    border-radius: 0.5rem;
    padding: 3.5rem 3rem;
    min-height: 26rem;
    box-shadow: -15px 30px 40px rgb(14 8 17 / 60%);
    font-weight: 500;
    font-size: fluid-font(1.8rem, 1.6rem);
    line-height: 150%;
    background-color: var(--clr-primary);

    &::before {
      content: "";
      position: absolute;
      right: 2.6rem;
      bottom: -0.6rem;
      width: 11.6rem;
      height: 8.2rem;
      background-image: url("../img/reviews/img@1x.svg");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    @media (max-width: em(767)) {
      padding: 1.5rem;
      min-height: 13rem;
    }
  }

  &__item + &__item {
    @media (max-width: em(460)) {
      margin-top: 1.5rem;
    }
  }
}
