.promotions {
  &__list {
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: em(1200)) {
      grid-gap: 1.5rem;
    }

    @media (max-width: em(767)) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: em(576)) {
      display: block;
    }
  }

  &__item {
    position: relative;
    overflow: hidden;
    border-radius: 0.7rem;
    padding: 3.5rem 3rem 8rem;
    background-color: var(--clr-primary);

    &-title {
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 120%;
    }

    &-text {
      margin-top: 1.5rem;
      font-size: 1.4rem;
      line-height: 135%;
      color: rgb(255 255 255 / 75%);
    }

    &:first-child {
      &::before {
        content: "Friday";
        position: absolute;
        left: 3rem;
        bottom: -3rem;
        z-index: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 10rem;
        font-weight: 600;
        font-size: fluid-font(9.6rem, 5.8rem);
        -webkit-text-stroke: 0.2rem transparent;
        text-transform: uppercase;
        color: var(--clr-primary);
        background-image: linear-gradient(180deg, #742763 0%, rgb(116 39 99 / 30%) 100%);
        -webkit-background-clip: text;
      }
    }

    &:last-child {
      &::before {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 0;
        width: 21.2rem;
        height: 14rem;
        background-image: url("../img/promotions/img@1x.svg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }

    &:nth-child(2) {
      &::before {
        content: "MONDAY";
        position: absolute;
        left: 2.5rem;
        bottom: 5rem;
        z-index: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 7rem;
        font-weight: 600;
        font-size: fluid-font(6rem, 4rem);
        -webkit-text-stroke: 0.2rem transparent;
        color: var(--clr-primary);
        background-image: linear-gradient(180deg, #742763 0%, rgb(116 39 99 / 30%) 100%);
        -webkit-background-clip: text;

        @media (max-width: em(576)) {
          bottom: -2rem;
        }
      }

      &::after {
        content: "FREE SPINS";
        position: absolute;
        right: 2.5rem;
        bottom: 2rem;
        z-index: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 5rem;
        font-weight: 600;
        font-size: fluid-font(3.2rem, 2.4rem);
        -webkit-text-stroke: 0.2rem transparent;
        color: var(--clr-primary);
        background-image: linear-gradient(180deg, #f4c062 0%, rgb(244 192 98 / 75%) 100%);
        -webkit-background-clip: text;

        @media (max-width: em(576)) {
          bottom: 1rem;
        }
      }
    }

    &-title,
    &-text {
      position: relative;
      z-index: 1;
    }

    & + & {
      @media (max-width: em(576)) {
        margin-top: 1.5rem;
      }
    }

    @media (max-width: em(992)) {
      padding: 2rem 1.5rem 6rem;
    }
  }
}
