.games {
  &__list {
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: repeat(6, 1fr);
    margin-top: 3rem;
    margin-bottom: 3.5rem;

    @media (max-width: em(1200)) {
      grid-gap: 1.5rem;
    }

    @media (max-width: em(992)) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: em(767)) {
      grid-template-columns: repeat(3, 1fr);
      margin-top: 2rem;
      margin-bottom: 2rem;
    }

    @media (max-width: em(576)) {
      grid-template-columns: repeat(2, 1fr)!important;
    }

    @media (max-width: em(420)) {
      display: block;
    }
  }

  &__item {
    border-radius: 0.5rem;
    padding: 1rem;
    min-height: 17rem;
    box-shadow: -15px 30px 40px rgb(14 8 17 / 60%);
    background-color: var(--clr-primary);

    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 2rem;
      border-radius: 0.5rem;
      min-height: 10rem;
      background-color: var(--clr-bg);
    }

    &-text {
      padding-right: 1rem;
      padding-left: 1rem;
    }
  }

  &__item + &__item {
    @media (max-width: em(420)) {
      margin-top: 1.5rem;
    }
  }
}
