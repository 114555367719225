.bonus {
  &__list {
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: repeat(8, 1fr);

    @media (max-width: em(1200)) {
      grid-gap: 1.5rem;
    }

    @media (max-width: em(992)) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: em(576)) {
      display: block;
    }
  }

  &__item {
    position: relative;
    overflow: hidden;
    border-radius: 0.7rem;
    padding: 4rem 3rem 11rem;
    background-color: var(--clr-primary);

    &-title {
      font-weight: 500;
      font-size: 1.8rem;
    }

    &-text {
      margin-top: 1.5rem;
      font-size: 1.4rem;
      line-height: 135%;
      color: rgb(255 255 255 / 75%);

      p + p {
        margin-top: 1rem;
      }
    }

    &:first-child,
    &:nth-child(2),
    &:last-child {
      @media (max-width: em(992)) {
        grid-column: initial;
      }
    }

    &:first-child {
      grid-column: 1/3;

      &::before {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        width: 27rem;
        height: 17rem;
        background-image: url("../img/bonuses/img1@1x.svg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }

    &:last-child {
      grid-column: 7/9;

      &::before {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        width: 22.3rem;
        height: 8.4rem;
        background-image: url("../img/bonuses/img4@1x.svg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }

    &:nth-child(2) {
      grid-column: 3/7;
      padding-right: 20rem;

      &::before {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        width: 42.9rem;
        height: 26.6rem;
        background-image: url("../img/bonuses/img2@1x.svg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }

      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 12.7rem;
        background-image: url("../img/bonuses/img3@1x.svg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        @media (max-width: em(992)) {
          display: none;
        }
      }

      @media (max-width: em(992)) {
        padding-right: 1.5rem;
      }
    }

    &-title,
    &-text {
      position: inherit;
      z-index: 1;
    }

    & + & {
      @media (max-width: em(576)) {
        margin-top: 1.5rem;
      }
    }

    @media (max-width: em(1200)) {
      padding: 2rem 1.5rem 6rem;
    }
  }
}
