.mobile {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: em(992)) {
      flex-direction: column;
    }
  }

  &__img {
    position: relative;
    max-width: 50.6rem;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      width: 18.6rem;
      height: 15.4rem;
      background-color: #722762;
      filter: blur(160px);
    }

    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      z-index: -1;
      width: 18.6rem;
      height: 15.4rem;
      background-color: rgb(244 192 98 / 20%);
      filter: blur(160px);
      transform: rotate(18.04deg) translateY(-50%);
    }
  }

  &__info {
    margin-right: 1.5rem;
    max-width: 57rem;

    @media (max-width: em(992)) {
      margin-right: initial;
      margin-bottom: 2rem;
      max-width: initial;
    }
  }
}
