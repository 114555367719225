.vip {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: em(992)) {
      flex-direction: column;
    }
  }

  &__info {
    max-width: 59rem;
    margin-right: 1.5rem;

    @media (max-width: em(992)) {
      max-width: initial;
      margin-right: initial;
      margin-bottom: 2rem;
    }
  }

  &__img {
    max-width: 55.7rem;
  }
}
