.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -0.1rem;
  border: 0;
  padding: 0;
  width: 0.1rem;
  height: 0.1rem;
  clip: rect(0 0 0 0);
}

.section {

  &__banner {
    margin: 2rem auto;
    max-width: 97rem;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: em(767)) {
      flex-direction: column;
    }
  }

  &__pd {
    &--t65 {
      padding-top: 6.5rem;

      @media (max-width: em(767)) {
        padding-top: 3rem;
      }
    }

    &--b65 {
      padding-bottom: 6.5rem;

      @media (max-width: em(767)) {
        padding-bottom: 3rem;
      }
    }
  }

  &__mr {
    &--t25 {
      margin-top: 2.5rem;
    }

    &--b40 {
      margin-bottom: 4rem;

      @media (max-width: em(767)) {
        margin-bottom: 2.5rem;
      }
    }
  }

  &__title {
    text-transform: uppercase;
    font-weight: 500;
    line-height: 155%;

    &--small {
      font-size: fluid-font(2.4rem, 2rem);
    }

    &--large {
      font-size: fluid-font(3.6rem, 2.4rem);
    }
  }

  &__text {
    line-height: 155%;
    color: var(--clr-default-100);

    p + p {
      margin-top: 1.5rem;
    }
  }

  &__btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 0.1rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    height: 5.5rem;
    min-width: 23.5rem;
    font-weight: 500;
    line-height: 155%;
    color: #1f1f20;
    background-image: linear-gradient(109.45deg, #ffcb6c 22.4%, #e2a433 77.68%);
    transition: box-shadow var(--transition);

    @media (hover) {
      &:hover {
        box-shadow: 0 15px 35px rgb(226 164 51 / 20%);
        transition: box-shadow var(--transition);
      }
    }
  }
}
