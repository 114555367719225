.vpn {
  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    border-radius: 0.5rem;
    padding: 6rem 5.5rem;
    background-color: var(--clr-primary);

    @media (max-width: em(992)) {
      flex-direction: column;
      margin-left: -1.5rem;
      border-radius: initial;
      padding: 3rem 2.5rem;
      width: calc(100% + 3rem);
    }

    @media (max-width: em(767)) {
      padding: 2rem 1.5rem;
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url('../img/vpn/bg.svg');
      background-position: right;
      background-size: contain;
      background-repeat: no-repeat;

      @media (max-width: em(992)) {
        display: none;
      }
    }
  }

  &__info {
    position: inherit;
    margin-right: 1.5rem;
    z-index: 1;
    max-width: 54.5rem;

    @media (max-width: em(992)) {
      margin-right: initial;
      margin-bottom: 2rem;
      max-width: initial;
    }
  }

  &__img {
    position: inherit;
    z-index: 1;
    max-width: 34.6rem;
  }
}
