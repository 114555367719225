.hero {
  padding-top: 6rem;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: em(992)) {
      flex-direction: column;
    }
  }

  &__info {
    margin-right: 1.5rem;
    max-width: 50rem;

    @media (max-width: em(992)) {
      margin-right: initial;
      max-width: initial;
      margin-bottom: 2rem;
    }
  }

  &__title {
    font-weight: 700;
    font-size: fluid-font(4.2rem, 3.4rem);
    line-height: 125%;
  }

  &__img {
    position: relative;
    max-width: 60.9rem;

    &::before {
      content: "";
      position: absolute;
      right: 12.2rem;
      top: 6.4rem;
      z-index: -1;
      width: 12.3rem;
      height: 12.3rem;
      background-color: rgb(246 191 91);
      filter: blur(160px);

      @media (max-width: em(992)) {
        display: none;
      }
    }
  }
}
